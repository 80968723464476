.submission-details {
    background-color: $white;

    .submission-details-container {
        padding-top: 2.5rem;
        padding-bottom: 8rem;
    }

    .submission-details-content {
        width: 100%;
        max-width: 630px;
        margin: 0 auto;

        @media screen and (max-width: 767px) {
            max-width: 100%;
        }
    }

    .submission-date {
        margin-bottom: 1rem;
    }

    .submission-title {
        margin-bottom: 2rem;
    }

    .submission-subtitle {
        margin-bottom: 1rem;
    }

    .submission-details-section {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        margin-top: 6rem;
        padding: 2.5rem;
        border-radius: 0.625rem;
        background-color: $blueGreyLight;
        
        .section-title {
            margin-bottom: 1.25rem;
        }

        @media screen and (max-width: 767px) {
            margin-top: 4rem;
        }
    }

    .submission-details-fields {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .submission-details-field {
        .h5 {
            margin-bottom: 0.5rem;
        }

        .field-asset {
            width: 100%;
            max-width: 300px;
            margin: 0 auto 1rem;

            video,
            img {
                display: block;
                width: 100%;
            }
        }

        .button {
            padding: 0.5rem 1rem;
        }

        .field-empty {
            color: $red;
        }
    }
}