.hero-image {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3.75rem;
    border-radius: 2.5rem;

    @media (max-width: 767px) {
        margin-top: 2.25rem;
        margin-bottom: 1.25rem;
        border-radius: 0.625rem;
    }
}

.hero-account {
    background: $blueDark;

    .site-container {
        padding-top: 4.25rem;
        padding-bottom: 4.25rem;
    }

    .hero-account-title {
        margin-bottom: 0.63rem;
        font-size: 2.8125rem;
        color: $white;
    }

    .hero-account-subtitle {
        color: $white;
    }
}