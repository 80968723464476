.shop-page {
  .main-cart-wrapper {
    padding: 0 1rem;

    .back-button {
      display: flex;
      align-items: center;
      color: $blueDark;

      svg {
        margin-right: 0.5rem;
      }
    }


    .h4 {
      color: $blueDark;
      font-weight: 500;
    }

    .order-status-wrapper {
      display: flex;
      width: 100%;
      max-width: 39.375rem;
      padding: 2.5rem 2.88rem 2rem 2.44rem;
      border-radius: 0.63rem;
      background: $blueLight;

      svg {
        flex-shrink: 0;
        margin-right: 1.25rem;
      }

      .title {
        margin-bottom: 1.25rem;
      }
    }

    .cart-titles {
      display: flex;
      align-items: center;
      margin: 5rem 0 5.19rem;
      color: $black;

      @media (max-width: 900px) {
        margin: 2.62rem 0 2.5rem;
      }

      .summary-titles {
        p {
          margin:0 ;
        }

        .title {
          font-family: $fontTitle;
          margin: 0.62rem 0 0.88rem;
        }
      }

      .title {
        span {
          color: $blueGrey;
        }
      }

      .price-tag {
        margin-left: auto;
      }
    }

    .cart-wrapper {
      display: flex;

      @media (max-width: 900px) {
        flex-direction: column;
      }

      .cart-left-panel {
        width: 100%;
        margin-right: 8.05rem;

        @media (max-width: 900px) {
          margin-right: 0;
        }

        .cart-address {
          margin-bottom: 3.75rem;

          @media (max-width: 900px) {
            margin-bottom: 2.69rem;
          }

          .title {
            color:$blueDark;
          }

          .address-wrapper {
            display: flex;
            width: 100%;
            align-items: center;
            padding: 2.31rem 2.5rem;
            margin: 1.88rem 0 0.88rem;
            border: 1px solid $greyLight;
            border-radius: 0.6rem;

            @media (max-width: 767px) {
              padding: 2.56rem 1.25rem;
            }

            p {

              @media (max-width: 767px) {
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.125rem;
              }
            }

            svg {
              flex-shrink: 0;
              margin-right: 2.5rem;
            }
          }

          .shipping-notice {
            color: $greyDark;
          }
        }

        .cart-summary {
          @media(max-width: 900px) {
            padding-top: 1.87rem;
            border-top: 1px solid $greyLight;
          }

          .summary-wrapper {
            margin: 1.88rem 0 1.25rem;
            padding: 2.5rem;
            background: $blueGreyLight;
            border-radius: 0.65rem;

            .product-info {
              display: flex;

              .product-image {
                background-position: center;
                width: 6.8125rem;
                height: 6.8125rem;
                margin-right: 1rem;
                flex-shrink: 0;
                border-radius: 0.38rem;
              }
            }

            .product-summary {
              width: 100%;

              .subtitle {
                color: $grey;
              }

              .product-titles {
                display: flex;
                margin: 0.38rem 0 1.25rem;

                .price-tag {
                  margin-left: auto;
                }
              }

              ul {
                padding-left: 1.3rem;

                li {
                  margin-bottom: 0.5rem;
                }
              }

              .product-extras {
                margin-top: 1.25rem;

                p {
                  &:not(:last-of-type) {
                    margin-bottom: 1.25rem;
                  }
                }
              }
            }

            .product-edit {
              margin-top: 2.5rem;
              text-align: right;

              span {
                margin: 0 0.5rem;
                color: $greyLight;
              }
            }
          }

          .cart-extra-info {
            max-width: 23.1875rem;

            @media (max-width: 900px) {
              max-width: initial;
            }

            .title {
              margin-bottom: 0.38rem;
            }
          }
        }
      }

      .cart-right-panel {
        width: 100%;
        max-width: 19rem;

        @media (max-width: 900px) {
          margin-top: 5rem;
          max-width: initial;
        }

        .total-summary {
          margin: 1.88rem 0;

          .total-row,
          .final-total-row {
            display: flex;
            align-items: center;

            .price-tag {
              margin-left: auto;
            }
          }

          .total-row {
            margin-bottom: 0.62rem;

            &.last-sub-row {
              padding-bottom: 1.88rem;
              border-bottom: 1px solid $greyLight;
            }
          }

          .final-total-row {
            margin-top: 1.88rem;
          }
        }

        .confirmation-wrapper {
          display: flex;
          margin-bottom: 1.88rem;
          align-items: start;

          input {
            margin-right: 0.5rem;
            margin-top: 0.25rem;
            border: 1px solid $blueGrey;

            &:hover {
              border-width: 2.5px;
            }
          }
        }

        .button {
          width: 100%;
        }
      }
    }
  }
}
