.account-container {
    padding-top: 6.25rem;
    padding-bottom: 9rem;
    background-color: $blueText;

    .account-tabs {
        display: flex;
        align-items: stretch;
        width: 100%;
        max-width: 540px;
        margin: 0 auto 3.15rem;

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }

        .account-tab {
            width: 50%;
            padding: 0.7rem 3rem;
            border-radius: 0.625rem 0 0 0.625rem ;
            border: none;
            color: $blueText;
            background-color: $white;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

            &:last-child {
                border-radius: 0 0.625rem 0.625rem 0;
            }

            &:hover {
                color: $white;
                background-color: $blueHover;
            }

            &.is-active {
                color: $white;
                background-color: $blueButton;
            }

            @media screen and (max-width: 767px) {
                width: 100%;
                border-radius: 0.625rem 0.625rem 0 0;

                &:last-child {
                    border-radius: 0 0 0.625rem 0.625rem;
                }
            }
        }
    }

    .form-container {
        @media screen and (max-width: 767px) {
            padding: 4rem 1rem 2.5rem;
        }
    }

    .account-content {
        display: none;
        opacity: 0;
        animation: tab-content 0.3s ease-in-out forwards;
        animation-direction: reverse;


        &.is-active {
            display: block;
            opacity: 1;
            animation: tab-content 0.3s ease-in-out forwards;
        }
    }
}

@keyframes tab-content {
    0% {
        opacity: 0;
        display: none;
    }

    5% {
        opacity: 0;
        display: block;
    }

    100% {
        opacity: 1;
        display: block;
    }
}