.alert-box {
    display: flex;
    gap: 1.25rem;
    padding: 2.5rem;
    border-radius: 0.625rem;
    background-color: $blueLight;

    svg {
        width: 3.125rem;
        height: 3.125rem;

        path {
            fill: $blueButton;
        }
    }

    .alert-box-content {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        h2, p {
            color: $black;
        }
    }
}