.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 30px 0 0;
  
    & > *:not(.ellipsis):not(.current) {
      cursor: pointer;

      &:hover {
        background-color: $blueHover;
      }
    }
  
    .pagination-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 5px;
      color: $white;
      background-color: $blueButton;
      transition: background-color .3s ease-in-out;
    }
  
    .current {
      font-weight: 600;
    }
  
    .prev,
    .next {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
      transition: background-color .3s ease-in-out;
    }
  }