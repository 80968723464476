// Titles
// -------------------------

.title,
h1, h2, h3, h4, h5 {
  margin: 0;
  font-family: Urbane;
  font-style: normal;
  font-weight: 500;
}

.title {

  &.tagline {
    font-size: 6rem;
    line-height: normal;

    @media screen and (max-width: 1023px) {
      font-size: 3rem;
    }
  }

  &.h1 {
    font-size: 1.875rem;
  }

  &.h2 {
    font-size: 1.5625rem;
  }

  &.h3 {
    font-size: 1.25rem;
  }

  &.h4 {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  &.h5 {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &.h6 {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}

// Paragraphs
// -------------------------

.text,
p {
  font-family: $fontText;
  font-style: normal;
  font-weight: 400;

  &.p24 {
    font-size: 1.5rem;
    line-height: normal;
  }

  &.p18 {
    font-size: 1.125rem;
    line-height: normal;
  }

  &.p16 {
    font-size: 1rem;
    line-height: 140%;
  }

  &.p14 {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  &.p12 {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}

.text-center {
  text-align: center;
}

.text-bold,
b, strong {
  font-weight: 700;
}

.text-underlined {
  text-decoration: underline;

  &-hover {
   &:hover {
     text-decoration: underline
   }
  }
}

.text-dark-blue {
  color: $blueText;
}

.text-blue {
  color: $blueButton;
}

.text-grey {
  color: $grey;
}

.text-gray-500 {
  color: $greyLight;
}

.text-gray-600 {
  color: $greyDark;
}

.text-red {
  color: $red;
}

.opacity8 {
 opacity: 0.8;
}
