.product-cart-preview {
    background-color: $blueLight;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &.is-active {
        max-height: 100%;
    }

    .site-container {
        padding-top: 3rem;
        padding-bottom: 7rem;

        @media screen and (max-width: 767px) {
            padding-bottom: 4.5rem;
        }
    }

    .cart-preview-title {
        margin-bottom: 3rem;
    }

    .cart-preview-content {
        display: flex;
        gap: 3.25rem;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            gap: 2.5rem;
        }
    }

    .cart-preview-col {
        width: 50%;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
        
        &:first-child {
            position: relative;

            @media screen and (max-width: 767px) {
                padding-bottom: 1.9rem;
            }
            
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 70%;
                background-color: $grey;

                @media screen and (max-width: 767px) {
                    top: auto;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                }
            }
        }
    }
    
    .shipping-label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 1rem;

        svg {
            width: 1.5rem;
        }
    }

    .shipping-address {
        width: 100%;
        max-width: 17rem;
        margin-bottom: 2.5rem;
        color: rgba($black, 0.8);

        @media screen and (max-width: 767px) {
            max-width: 100%;
        }
    }

    .button {
        width: 100%;
        max-width: 22rem;

        @media screen and (max-width: 767px) {
            max-width: 100%;
        }
    }
}

.cart-preview-products {
    display: flex;
    gap: 1.45rem;

    .product-image {
        position: relative;
        overflow: hidden;
        width: 6.8125rem;
        height: 6.8125rem;        
        border-radius: 0.375rem;
        aspect-ratio: 1/1;

        @media screen and (max-width: 767px) {
            width: 4.4375rem;
            height: 4.4375rem;
            flex-shrink: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    
    .product-title,
    .product-price,
    .product-qty {
        margin-bottom: 1.5rem;
    }

    .product-description {
        margin-bottom: 0 !important;
    }

    .product-extra {
        margin-top: 1.25rem;
    }
}