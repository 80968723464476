.product {
    background-color: $white;

    .product-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 5rem;
        padding-top: 2.5rem;

        &.has-error {
            gap: 2rem;
            justify-content: center;
            align-items: center;
        }

        @media (max-width: 767px) {
            margin-bottom: 2.5rem;
        }
    }

    .product-section {
        display: flex;
        gap: 3.2rem;

        @media (max-width: 767px) {
            flex-direction: column;
            gap: 1.75rem;
        }
    }

    .product-images {
        display: flex;
        gap: 0.8rem;
        width: 50%;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .product-image-thumbs {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        img {
            width: 5.625rem;
            height: 5.625rem;
            border-radius: 0.375rem;
            cursor: pointer;
        }

        @media (max-width: 767px) {
            display: none;
        }
    }

    .product-image-wrapper {
        position: relative;

        @media (max-width: 767px) {
            overflow-x: auto;
            display: flex;
            gap: 1rem;
            width: 100%;

            &.is-full {
                justify-content: center;
            }
        }

        .product-image {
            position: absolute;
            width: 100%;
            border-radius: 0.375rem;
            overflow: hidden;
            opacity: 0;
            aspect-ratio: 1/1;
            transition: opacity 0.3s ease-in-out;
            transition-delay: 0;

            &.is-active {
                position: relative;
                opacity: 1;
                transition-delay: 0.3s;
            }

            @media (max-width: 767px) {
                position: relative;
                opacity: 1;
            }

            img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .product-help {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        width: 100%;
        max-width: 370px;
        margin-top: 1.25rem;

        @media (max-width: 767px) {
            display: none;
            margin-top: 2.5rem;
        }

        strong {
            color: $blueDark;
        }

        .title {
            color: $black;
        }
    }

    .product-details {
        display: flex;
        flex-direction: column;
        width: 50%;

        @media (max-width: 767px) {
            width: 100%;
        }

        .product-small-text {
            margin-bottom: 0.25rem;
        }

        .product-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            margin-bottom: 3.5rem;

            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .product-name {
            width: 100%;
            max-width: 18rem;
        }

        .product-price {
            .on-sale {
                @extend .text, .p18;
                text-decoration: line-through;
            }
        }

        .product-description {
            margin-bottom: 2.5rem;

            ul {
                padding-left: 1rem;

              li {
                margin-bottom: 0.5rem;
              }
            }
        }

        .product-delete {
            padding: 0;
            border: none;
            background-color: transparent;
            cursor: pointer;

            svg {
                width: 1.5rem;
                height: 1.5rem;
                fill: $black;
            }
        }

        .product-quantity-wrapper {
            label {
                margin-bottom: 1.3rem;
                font-weight: 500;
            }

            button {
                padding: 0;
                border: none;
                background-color: transparent;
                cursor: pointer;

                &:disabled {
                    cursor: not-allowed;

                    svg circle {
                        fill: $greyLight;
                    }
                }

                svg {
                    width: 2.125rem;
                    height: 2.125rem;


                    circle {
                        fill: $blueGrey;
                        transition: fill 0.3s ease-in-out;
                    }
                }
            }

            input {
                width: 100%;
                text-align: center;
            }

            .form-field {
                max-width: 5.2rem;
                padding: 0.64rem 0.5rem;
            }

            .product-quantity {
                display: flex;
                align-items: center;
                gap: 0.75rem;
            }

            svg {
                width: 2.125rem;
            }
        }

        .product-related-products {
            position: relative;
            overflow: hidden;
            margin-top: 3.5rem;
            padding: 1.3rem;
            border-radius: 0.375rem;
            background-color: $blueGreyLight;

            &.is-active {
                .related-products-header svg {
                    &.icon-minus {
                        display: block;
                    }

                    &.icon-plus {
                        display: none;
                    }
                }

                .related-products-container {
                    height: 100%;
                    padding-top: 2.5rem;
                    transition-delay: 0s;

                    .related-product {
                        opacity: 1;
                        transition-delay: 0.3s;
                    }
                }
            }

            @media screen and (max-width: 767px) {
                margin-top: 2.5rem;
            }
        }

        .related-products-header {
            position: relative;
            width: 100%;
            padding: 0 2rem 0 0;
            border: none;
            text-align: left;
            background-color: transparent;
            cursor: pointer;

            svg {
                position: absolute;
                top: 50%;
                right: 0;
                width: 1.5rem;
                transform: translateY(-50%);

                &.icon-minus {
                    display: none;
                }

                &.icon-plus {
                    display: block;
                }
            }
        }

        .related-products-container {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding-top: 0;
            height: 0;
            transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;
            transition-delay: 0.3s;

            .related-product {
                display: flex;
                justify-content: space-between;
                padding-bottom: 1rem;
                border-bottom: 1px solid $greyLight;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                transition-delay: 0;

              &:last-of-type {
                margin-bottom: 1.5rem;
              }

                @media screen and (max-width: 1023px) {
                    flex-wrap: wrap;
                    gap: 1rem;

                    .related-product-col {
                        width: calc(50% - 0.5rem);

                        &:nth-child(2) {
                            order: 3;
                            width: 100%;
                        }

                        &:last-child {
                            order: 2;
                            justify-content: flex-end;
                        }
                    }
                }
            }

            .related-product-col {
                width: calc(100% / 3);

                &:last-child {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 1rem;
                    align-self: start;
                    width: fit-content;
                }
            }

            .product-quantity-wrapper {
                .form-field {
                    max-width: 2.8rem;
                }
            }
        }
    }
}
