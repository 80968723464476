header {
    &.theme-light {
        background-color: $white;

        .header-user-menu {
            background-color: $white;
            box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);

            a {
                &:hover {
                    background-color: $blueLight;
                }
            }
        }
    }

    .site-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    
    .header-logo {
        width: 6.2rem;

        svg {
            display: block;
            width: 100%;
        }
    }

    .header-user-wrapper {
        position: relative;
    }

    .header-user {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        border: none;
        background-color: transparent;
        cursor: pointer;

        svg {
            width: 0.7rem;
            transition: transform 0.3s ease-in-out;

            path {
                fill: $blueText;
            }
        }

        &.is-active {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .header-user-menu {
        position: absolute;
        top: calc(100% + 1.2rem);
        left: 50%;
        z-index: 10;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 0 0 0.325rem 0.325rem;
        background-color: $blueLight;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
        transform: scaleY(0) translateX(-50%);
        transform-origin: top center;
        transition: transform 0.3s ease-in-out;

        &.is-active {
            transform: scaleY(1) translateX(-50%);
        }

        a {
            padding: 0.5rem 1rem;
            border-bottom: 1px solid $blueGreyLight;
            transition: background-color 0.3s ease-in-out;

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background-color: $white;
            }
        }
    }
}