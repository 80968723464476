.platform-landing {
    background-color: $white;

    &.theme-green {
        background-color: $green;
        color: $white;

        .title {
            color: $white;
    
            &.platform-period {
                color: $white;
            }
        }

        .shop-help-content {
            .title {
                color: $white;
            }

            strong {
                color: $blueHover;
            }
        }

        .button.is-outlined {
            @extend .is-outlined-light;
        }

        footer {
            background-color: $white;

            svg path {
                fill: $blueDark;
            }

            .footer-credits {
                color: $blueDark;
            }
        }
    }

    main {
        padding-bottom: 3rem;
    }

    .landing-content {
        display: flex;
        align-items: stretch;
        gap: 8rem;

        @media screen and (max-width: 1023px) {
            gap: 4rem;
        }
        
        @media screen and (max-width: 767px) {
            flex-direction: column;
            gap: 2.5rem;
        }

        .content-col {
            width: 40%;

            &:first-child {
                width: calc(55% - 8rem);
            }
            
            @media screen and (max-width: 1023px) {
                width: 50%;
                
                &:first-child {
                    width: calc(50% - 4rem);
                }
            }

            @media screen and (max-width: 767px) {
                width: 100%;

                &:first-child {
                    width: 100%;
                }
            }
        }
    }

    .title {
        color: $blueDark;

        &.platform-period {
            margin-top: 1.25rem;
            color: $blueGrey;

            @media screen and (max-width: 767px) {
                font-size: 1.5625rem;
            }
        }
    }

    .shop-help-content {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        width: 100%;
        max-width: 370px;
        margin-top: 6rem;

        @media (max-width: 767px) {
            display: none;
            margin-top: 2.5rem;
        }

        &.for-mobile {
            display: none;

            @media (max-width: 767px) {
                display: flex;
            }
        }

        strong {
            color: $blueDark;
        }

        .title {
            color: $black;
        }
    }

    .buttons {
        display: flex;
        gap: 0.6rem;
        margin-top: 3rem;

        @media (max-width: 767px) {
            flex-direction: column;
            gap: 1.25rem;
            margin-top: 2.5rem;
        }

        .button {
            width: 50%;

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        form {
            width: 100%;

            .button {
                width: 100%;
            }
        }
    }
}

.platform-details {
    background-color: $white;

    .platform-details-container {
        padding-top: 2.5rem;
        padding-bottom: 8rem;
    }

    .platform-details-content {
        width: 100%;
        max-width: 630px;
        margin: 0 auto;

        @media screen and (max-width: 767px) {
            max-width: 100%;
        }
    }

    .submission-date {
        margin-bottom: 1rem;
    }

    .platform-title {
        margin-bottom: 2rem;
    }

    .platform-actions {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }

    .platform-details-section {
        margin-top: 6rem;

        .section-title {
            margin-bottom: 1.25rem;
        }

        @media screen and (max-width: 767px) {
            margin-top: 4rem;
        }
    }

    .platform-details-products {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .products-item {
        display: flex;
        gap: 1rem;
        width: 100%;
        padding: 2.5rem;
        border-radius: 0.625rem;
        background-color: $blueGreyLight;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            gap: 1.5rem;
            padding: 1.5rem;
        }
        
        .item-image-wrapper {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 6.8125rem;
            height: 6.8125rem;
            border-radius: 0.375rem;
            aspect-ratio: 1/1;

            @media screen and (max-width: 767px) {
                width: 10.5rem;
                height: 10.5rem;
                margin: 0 auto;
            }
        }

        .item-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        .item-subtitle {
            margin-bottom: 0.38rem;
        }

        .item-name {
            margin-bottom: 1.25rem;
        }

        .item-description {
            color: rgba($black, 0.8);

            ul {
                padding-left: 1rem;
            }
        }
    }

    .platform-details-resources {
        display: flex;
        flex-direction: column;
    }

    .resources-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        padding: 0.8rem 0;
        border-bottom: 1px solid $blueGrey;

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }

        .left {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }
}