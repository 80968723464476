.form-container {
    width: 100%;
    max-width: 540px;
    margin: 5rem auto 10rem;
    padding: 4rem 2.75rem 2.5rem;
    border-radius: 0.625rem;
    background-color: $white;

    @media screen and (max-width: 767px) {
        margin: 5rem auto;
        padding: 0;
    }

    .button {
        width: 100%;
        margin-top: 1.25rem;
    }

    .form-top {
        margin-bottom: 3.5rem;
    }

    .form-bottom {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        margin-top: 2rem;

        .link {
            justify-content: center;
        }
    }
}

.form-title {
    margin-bottom: 3.75rem;
}

.form-small-title {
    margin-top: 1.90rem;
    margin-bottom: 1rem;

    &:first-of-type {
        margin-top: 0;
    }
}

.form-section {
    margin-bottom: 2.5rem;

    &:last-child {
        margin-bottom: 0;
    }

    ul {
        padding-left: 1rem;
        list-style-type: none;

        li {
            &::before {
                content: "";
                border-color: transparent $blueDark;
                border-style: solid;
                border-width: 0.3em 0 0.3em 0.4em;
                border-radius: 0.15rem;
                display: block;
                height: 0;
                width: 0;
                left: -1em;
                top: 0.9em;
                position: relative;
              }
        }
    }
}

.form-row {
    display: grid;
    grid-template-columns: repeat(2,calc(50% - 0.5rem));
    gap: 1rem;
    flex-wrap: wrap;
    width: 100%;

    &.one-column {
        grid-template-columns: 1fr;
    }
}

.form-field-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: .75rem;

    &:last-child {
        margin-bottom: 0;
    }

    &.is-hidden {
        display: none;
    }

    &.has-error {
        .form-field {
            border-color: $red;

            svg path {
                fill: $red;
            }

            input {
                color: $red;
            }
        }

        .form-field-error {
            display: flex;
        }
    }

    label {
        @extend .p14, .text-bold;
        margin-bottom: .5rem;
    }
}

.form-field {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem 1rem;
    padding: 0.64rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid $blueGrey;
    transition: border-color 0.3s ease-in-out;

    &:hover {
      margin: -1.5px;
      border-width: 2.5px;
    }

    &.is-filled {
      .input-placeholder {
        opacity: 1;
      }

      input,
      textarea {
        padding: 1rem 0 0;
      }
    }

    .input-placeholder {
      position: absolute;
      top: 0.47rem;
      left: 1rem;
      color: $blueGrey;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;
      opacity: 0;
      transition: opacity .3s ease-in-out;
      pointer-events: none;
    }

    svg {
        width: 1.75rem;
        height: 1.75rem;

        path {
            fill: $blueGrey;
            transition: fill 0.3s ease-in-out;
        }

      ~.input-placeholder {
        left: 3.75rem;
      }
    }

    textarea,
    input {
        flex: 1;
        padding: 0;
        border: none;
        background-color: transparent;
        transition: color 0.3s ease-in-out, padding .3s ease-in-out;
        resize: none;
    }

    select {
        position: relative;
        z-index: 1;
        flex: 1;
        border: none;
        background-color: transparent;
        appearance: none;
    }

    .select-arrow {
        position: absolute;
        top: 50%;
        right: 1rem;
        width: 0.565rem;
        height: 0.9375rem;
        transform: translateY(-50%);
    }

    .fui-file-summary {
        @extend .p14;
        width: 100%;
        color: $blueText;
    }

    .checkbox-container {
        margin-bottom: 0;
    }

    .fui-checkbox {
      input:checked+label:before {
        background-color: $black;
      }
    }

    .fui-checkbox-label {
      outline: none;

      &:before {
        transition: none;
        border-color: $blueGrey;
        box-shadow: none;
      }

      &:hover {
        &:before {
          border-width: 2.5px;
          margin: -1.5px;
          border-color: $blueGrey;
        }
      }
    }

    &.is-checkbox {
        padding: 0;
        border: none;
    }

    &.is-nice-select {
        display: inline-flex;
        flex-direction: column;

        select {
            position: absolute;
        }

        .nice-select {
            display: inline-flex;
            align-items: center;
            width: 100%;
            height: auto;
            padding: 0;
            border: none;

            &:after {
                content: none;
            }

            .nice-select-dropdown {
                top: calc(100% + 0.4rem);
                left: -1rem;
                width: calc(100% + 2rem);
                margin-top: 0;
                box-shadow: none;
                border: 1px solid $blueGrey;
                border-radius: 0 0 5px 5px;
            }

            .current {
                overflow: hidden;
                width: calc(100% - 20px);
                text-overflow: ellipsis;
            }

            .option {
                white-space:break-spaces;
                border-bottom:1px solid $blueGrey;

                &.is-hidden {
                    display: none;
                }
            }
        }
    }
}

.form-field-error {
    display: none;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.3rem;

    svg {
        fill: $red;
    }

    &.is-active {
        display: flex;
    }

    &.is-centered {
        justify-content: center;
    }
}

.fui-error-message {
    @extend .text, .p12, .text-bold;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.3rem !important;
    color: $red !important;
    font-size: 0.75rem !important;

    svg {
        fill: $red;
    }

    &.is-active {
        display: flex;
    }

    &.is-centered {
        justify-content: center;
    }
}

.checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    label {
        font-weight: 400;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
            border-color: $black;
            background-color: $black;
        }

        &:checked ~ .checkmark:after {
            display: block;
        }
    }

    .checkmark {
        position: absolute;
        top: 50%;
        left: 0;
        width: 1rem;
        height: 1rem;
        border-radius: 0.15rem;
        border: 1px solid $blueGrey;
        background-color: transparent;
        transform: translateY(-50%);
        transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

        &:after {
            content: "";
            position: absolute;
            top: 0.12rem;
            left: 0.32rem;
            display: none;
            width: 3px;
            height: 7px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    .fui-checkbox input:checked+label:before {
        background-color: $black;
        border-color: $black;
    }
}

.file-upload-container {
    position: relative;

    input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        opacity: 0;
    }

    .file-title {
        display: none;
        align-items: center;
        gap: 0.5rem;
        margin-top: 0.35rem;

        &.is-active {
            display: flex;
        }

        svg {
            width: 1rem;

            path {
                fill: $blueButton;
            }
        }
    }

    .image-preview {
        display: none;
        margin-top: 0.5rem;
        width: 100%;
        max-width: 300px;

        &.is-active {
            display: block;
        }
    }

    .button {
        margin: 0;
        width: 100%;
        max-width: 230px;
    }
}

.form-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.6rem;
    margin-bottom: 1.9rem;

    h5 {
        text-transform: uppercase;

        span {
            color: $blueGrey;
        }
    }

    svg {
        width: 3rem;
    }
}

.form-text {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 1.25rem;
    }

    p {
        @extend .p14;

        margin-bottom: 0.75rem;
    }
}
