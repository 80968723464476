// Fonts
// --------------------------------------------------
$fontTitle: 'Urbane', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontText: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

// Colors
// --------------------------------------------------

// Primary
$blueLight: #EBF2FC;
$blueButton: #008CD3;
$blueText: #073881;
$blueDark: #05286A;

// Secondary
$blueGrey: #647594;
$blueGrey700: #929EB3;
$blueGreyLight: #E2E8F0;
$blueHover: #2EB9FF;

// Theme
$green: #4D5F5D;

// Shades of grey
$black: #212121;
$grey: #838383;
$greyLight: #B3B6BA;
$greyDark: #525455;
$white : #fff;
$background: #F7FAFC;

// Functional
$red: #FF0000;
