// --------------------------------------------------
// Base
// --------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

html {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: sans-serif;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  
}

.fonts-loaded body {
  font-family: $fontText;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Helvetica, Arial, sans-serif;
}

.fonts-loaded h1,
.fonts-loaded h2,
.fonts-loaded h3,
.fonts-loaded h4,
.fonts-loaded h5,
.fonts-loaded h6 {
  font-family: $fontTitle;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

/* -- No outline for non-keyboard-inputs elements */
button:focus,
select:focus {
    outline: none;
}

html.user-is-tabbing *:focus {
    outline: 2px solid var(--outlineColor) !important; /* for non-webkit browsers */
    outline: 5px auto -webkit-focus-ring-color !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-ms-input-placeholder,
:-ms-input-placeholder,
::placeholder {
  color: $blueGrey;
  opacity: 1;
}

.fui-input,
input,
textarea,
select {
  outline: none;

  &:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

main {
  min-height: calc(100vh - 13.5rem);
}

.custom-git-branch {
  position: fixed;
  display: flex;
  align-items: center;
  left: 20px;
  bottom: 20px;
  padding: 5px 10px;
  border-radius: 6px;
  background-color: $blueButton;
  color: $white;
  z-index: 999999;
  pointer-events: none;

  & > svg {
    width: auto;
    margin-right: 5px;
  }
}

.site-wrapper {
  width: 100%;
  background-color: $blueLight;

  @media screen and (max-width: 767px) {
    background-color: $white;
  }
}

.site-container {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 5rem;
  padding-right: 5rem;

  @media (max-width: 1023px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media (max-width: 767px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}