footer {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: $blueDark;

    @media screen and (max-width: 767px) {
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
    }

    .site-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2.5rem;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }
    }

    .footer-col {
        display: flex;
        align-items: center; 
        gap: 2.2rem;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            gap: 2.5rem;
        }
    }

    svg path {
        fill: $white;
    }

    .footer-socials {
        display: flex;
        align-items: center;

        a {
            display: inline-block;
            width: 100%;
            max-width: 25px;
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            svg {
                display: block;
                width: 100%;
            }
        }
    }

    .footer-logos {
        svg {
            display: block;
            width: 100%;
        }
    }

    .footer-credits {
        color: $white;

        @media screen and (max-width: 767px) {
            text-align: center;
        }
    }
}
