.shop-page {
  background-color: $white;

  .shop-wrapper,
  .main-cart-wrapper {
    display: block;
    width: 100%;
    max-width: 68.4rem;
    margin: 2.5rem auto 6.38rem;

    @media (max-width: 767px) {
      margin: 4.81rem auto 4.5rem;
    }
  }

  .footer {
    background-color: $blueDark;

    .footer-wrapper {
      max-width: initial;
      padding: 15px 60px;
      align-items: center;
      justify-content: left;
      flex-direction: row;
      background-color: transparent;

      .socials-wrapper {
        width: fit-content;

        .social-link {
          width: 25px;

          svg path {
            fill: $white;
          }
        }
      }

      .website-logo {
        margin-left: 2.19rem;
        width: 236px;

        svg  {
          width: 236px;

          path {
            fill: $white;
          }
        }
      }

      .credits-footer {
        p {
          color: $white;
          text-align: right;
        }
      }
    }
  }
}
