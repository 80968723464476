button.fui-submit,
.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 1rem 3rem;
    border-radius: 0.375rem;
    border: none;
    color: $white;
    font-family: $fontText;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    text-decoration: none;
    background-color: $blueButton;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: $blueHover;
    }

    &.is-disabled,
    &:disabled {
        opacity: 0.4;
        pointer-events: none;
        cursor: not-allowed;
    }

    &.is-outlined-blue {
        background-color: transparent;
        border: 1px solid $blueButton;
        color: $blueButton;

        &:hover {
            background-color: $blueButton;
            color: $white;

            svg {
                fill: $white;

                path {
                    fill: $white;
                }
            }
        }

        svg {
            fill: $blueButton;

            path {
                fill: $blueButton;
            }
        }
    }

    &.is-outlined {
        background-color: transparent;
        border: 1px solid $blueDark;
        color: $blueDark;

        &:hover {
            background-color: $blueDark;
            color: $white;

            svg {
                fill: $white;

                path {
                    fill: $white;
                }
            }
        }

        svg {
            fill: $blueDark;

            path {
                fill: $blueDark;
            }
        }
    }

    &.is-outlined-light {
        background-color: transparent;
        border: 1px solid $white;
        color: $white;

        &:hover {
            background-color: $white;
            color: $blueText;

            svg {
                fill: $blueText;

                path {
                    fill: $blueText;
                }
            }
        }

        svg {
            fill: $white;

            path {
                fill: $white;
            }
        }
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: $white;
        transition: fill 0.3s ease-in-out;

        path {
            fill: $white;
            transition: fill 0.3s ease-in-out;
        }
    }

  &.download-button {
    background: $blueGrey;
    border-radius: 6.25rem;

    &:hover {
      background: rgba($blueGrey, 0.8);
    }
  }
}

.link {
    @extend .text, .p14, .text-bold;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    svg {
        width: 1.5rem;
    }
}

.back-link {
    @extend .p16, .text-dark-blue;
    margin-left: 7rem;
    margin-bottom: 8rem;

    @media screen and (max-width: 1023px) {
        margin-left: 0;
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 4rem;
    }
}
