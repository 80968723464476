.consultations {
    .form-container {
        max-width: 630px;
    }

    .filters {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-bottom: 6rem;
    }

    .form-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-top: 0;

      &.view-only-button {
        width: initial;
        justify-content: left;
        margin-top: 0.7rem;
      }

        .button {
            margin: 0;
            width: 100%;
            max-width: 130px;
            padding: 0.5rem 0.8rem;
        }
    }

    .consultations-listing {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .consultation-item {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        padding: 2.5rem;
        border-radius: 0.8rem;
        background-color: $blueGreyLight;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            gap: 1.5rem;
            padding: 1.5rem;
        }

        .item-content {
            display: flex;
            flex-direction: column;
            gap: 0.65rem;
        }

        .item-divider {
            width: 70%;
            height: 1px;
            margin: 0.5rem 0;
            background-color: $blueDark;
        }

        .item-field {
            .h5 {
                margin-bottom: 0.5rem;
            }
        }
    }

    .item-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 1px solid $blueDark;

        .button {
            margin: 0;
            width: 100%;
            max-width: fit-content;
            padding: 0.5rem 0.6rem;

            svg {
                width: 1rem;
                height: 1rem;
            }
        }
    }
}
